<template>
    <div class="tab__navigation container">
        <div class="tab__navigation__tabs">
            <div class="tab" v-for="tab, index in tabs" :key="index">
                <button  
                    class="tab__button" :class="{selected: tab == tabSelected}"
                    @click="selectTab(tab)"
                >
                    {{ tab }}
                </button>
            </div>
        </div>
    </div>  
</template>

<script>
export default {
    props: {
        tabs: { type: Array, require: true },
    },
    data() {
        return {
            tabSelected: 'editions'
        }
    },
    methods: {
        selectTab(tabSelected) {
            this.tabSelected = tabSelected;
            this.$emit("click", this.tabSelected);
        }
    }
}
</script>

<style lang="scss">
.tab__navigation {
    &.container {
        max-width: 1400px;
    }
    &.box {
        padding: 0px 16px;
    }
    &__tabs {
        display: flex;
        column-gap: 26px;
        .tab {
            button {
                &.tab__button {
                    all: unset;
                    cursor: pointer;
                    color: var(--color-neutral-02);
                    border-bottom: 1px solid var(--color-neutral-02);
                    font-size: 30px;
                    line-height: 38px; 
                    font-weight: 700;
                    padding-bottom: 10px;
                    text-transform: capitalize;
                    @include respond-to ("xsmall and down"){
                        font-size: 16px;
                        line-height: 24px;
                    }
                }
                &.selected {
                    color: var(--color-emerald);
                    border-bottom: 1px solid var(--color-emerald);
                }
            }
        } 
    }
}
</style>
