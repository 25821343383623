<template>
  <div class="thumbnail">
    <div class="thumbnail__img">
        <router-link
            :to="{ 
                name: 'test-mint-details',
                params: { projectId: project_id,  testId: detail_id } 
            }"
        >
            <img :src="image_url" alt="Thumbnail Image" />
        </router-link>
    </div>
    <div class="thumbnail__footer">
      <span>{{ title }} Test Mint #{{ art_number }}</span>
      <router-link
        :to="{ 
            name: 'test-mint-details',
            params: { projectId: project_id,  testId: detail_id } 
        }"
      >
        token details
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
      default: "The bigbang test",
    },
    art_number: {
      type: String,
      required: true,
      default: "1",
    },
    image_url: {
        type: String,
        required: true,
        default: 'https://eprops-images.dev-lk.mx/projects/1/arts/1000001/preview.png'
    },
    detail_id: {
        type: String,
        required: true,
        default: "1",
    },
    project_id: {
        type: String,
        required: true,
        default: "1",
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.thumbnail {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  &__img {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
    }
  }
  &__footer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    row-gap: 8px;
    background-color: transparent;
    span {
        margin: 0px;
        line-height: 24px;
    }
    a {
        text-decoration: none;
        color: var(--color-emerald);
        line-height: 18px;
        font-weight: 400;
    }
  }
}
</style>
